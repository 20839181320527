export const SPECIES = {
	human: {
		specie: 'human',
		label: 'Human',
		value: 100,
		unit: 'YEAR'
	},
	monkey: {
		specie: 'monkey',
		label: 'Monkey',
		value: 40,
		unit: 'YEAR'
	},
	rat: {
		specie: 'rat',
		label: 'Rat',
		value: 43.3333333,
		unit: 'MONTH'
	},
	mouse: {
		specie: 'mouse',
		label: 'Mouse',
		value: 42.0333333,
		unit: 'MONTH'
	},
	killifish: {
		specie: 'killifish',
		label: 'Killifish',
		value: 28.2857143,
		unit: 'WEEK'
	},
	worm: {
		specie: 'worm',
		label: 'Worm',
		value: 25,
		unit: 'DAY'
	}
}

// This is used to transform the values
export const DAYS_IN_YEAR = 365;
export const DAYS_IN_MONTH = 30; // Currently not used.
export const DAYS_IN_WEEK = 7;

// The source data uses different writings that the scripts
export const MAPPINGS = {
	'years': 'YEAR',
	'months': 'MONTH',
	'weeks': 'WEEK',
	'days': 'DAY'
}

// The number of projects listed per page
export const ITEMS_PER_PAGE = 20;

// The paths to the source files
export const PATH_DATA_STUDIES = './data/studies.json'
export const PATH_DATA_LIFESPANS = './data/lifespan.json'

// These are the columns in the table and the facets. The order here also defines the order in the interface.
export const PROPERTIES = [{
  	label: 'Species',
  	key: 'species',
  	isFacet: false,
  }, {
  	label: 'Experiment',
  	key: 'experiment',
  	isFacet: true
  }, {
  	label: 'Ages',
  	key: 'ages',
  	isFacet: false
  }, {
  	label: 'Brain Region',
  	key: 'region',
  	isFacet: true
  }, {
  	label: 'Genes',
  	key: 'genes',
  	isFacet: true
  }, {
  	label: 'Sex',
  	key: 'sex',
  	isFacet: true
  }, {
  	label: 'PI',
  	key: 'pi',
  	isFacet: true
  }, {
  	label: 'Parent study',
  	key: 'name',
  	isFacet: true
  }]

// These are the keys that are used by the search functionality
export const SEARCH_KEYS = [
	'species',
	'experiment',
	'experiment-original',
	'region',
	'region-original',
	'genes',
	'sex',
	'pi',
	'spi',
	'name'
];

// These settings define the display of the two graphs
export const PADDING_GRAPH_LEFT = 60;
export const PADDING_GRAPH_RIGHT = 10;
export const PADDING_GRAPH_TOP = 60;
export const PADDING_LIFESPAN_TOP = 20;
export const PADDING_GRAPH_BOTTOM = 20;
export const DOT_RADIUS = 4;
export const MIN_PADDING_TOP = Math.max(PADDING_GRAPH_TOP, DOT_RADIUS + 2);
export const MIN_PADDING_BOTTOM = Math.max(PADDING_GRAPH_BOTTOM, DOT_RADIUS + 2);
export const PADDING_AXIS_TOP = 10;
export const LIFESPAN_TICKS = 5;

// The labels in the graph
export const TEXT_LIFESPAN_START = 'Start of life';
export const TEXT_LIFESPAN_END = ['Maximum life expectancy', '(used in studies)'];

// The keys in order in which the results are sorted by default
export const DEFAULT_SORT_FUNCTIONS = [
	'speciesID',
	'name',
	'pi',
	'minInPercent',
	'maxInPercent',
	'experiment',
	'region',
	'genes',
	'sex'
];

// The key which stores the id of each study. You don’t need to change that.
export const KEY_STUDY_ID = 'studyID';
