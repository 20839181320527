<script>
	import Lifespan from './Lifespan/Lifespan.svelte';
	import Graph from './Graph/Graph.svelte';
	import Head from './Lifespan/Head.svelte';
	import List from './List/List.svelte';
	import Selector from './Selector/Selector.svelte';
	import Species from './Species/Species.svelte';
	import "../node_modules/tippy.js/dist/tippy.css";
	import "../node_modules/tippy.js/themes/light-border.css";
	import "../node_modules/normalize.css/normalize.css";

	let height;
</script>

<Species />
<div class="page-vis" role="figure">
	<span class="instruction condensed" bind:clientHeight={height}>Set the age range for your project search:</span>
	<Head />
	<Selector height={height} />
	<Lifespan />
	<Graph />
</div>
<List />

<style type="scss" global>
	@import './styles/base.scss';

	main {
		margin: 0 auto;
		display: grid !important;
		grid-template-columns: repeat(5, 1fr);
		grid-template-rows: auto 1fr;
		grid-row-gap: calc(var(--spacing-outer) * 2);
		max-width: 2200px;
		justify-items: stretch;
		grid-template-areas:
			"species species species species species"
			"vis vis vis vis vis"
			"list list list list list";

    @include query($wide) {
    	grid-column-gap: calc(var(--spacing-outer) * 5);
    	grid-row-gap: 0;

      grid-template-areas:
				"species species list list list"
				"vis vis list list list";
    }

		.page-vis {
			grid-area: vis;
			margin-left: var(--spacing-outer);
			width: calc(100% - var(--spacing-outer));
			position: relative;
			margin-top: calc(var(--spacing-outer) * 3);

			@include query($medium) {
	      width: calc(100% - var(--spacing-outer) * 1.5);
	    }
		}
	}
</style>