<script>
	import {
		PADDING_GRAPH_LEFT,
		PADDING_GRAPH_RIGHT,
		MIN_PADDING_TOP,
		TEXT_LIFESPAN_START,
		TEXT_LIFESPAN_END,
		PADDING_AXIS_TOP
	} from '../config.js';

	export let height;
	export let width;
	export let isFirstAxis = false;
</script>

<g class="axis">
	{#if width}
	<line
		x1={PADDING_GRAPH_LEFT}
		x2={PADDING_GRAPH_LEFT}
		y1={isFirstAxis ? (PADDING_AXIS_TOP + 5) : 0}
		y2={height} />
	<line
		x1={width - PADDING_GRAPH_RIGHT}
		x2={width - PADDING_GRAPH_RIGHT}
		y1={isFirstAxis ? (PADDING_AXIS_TOP + 5) : 0}
		y2={height} />
	{#if isFirstAxis}
	<text
		x={PADDING_GRAPH_LEFT - 3}
		y={PADDING_AXIS_TOP}
		text-anchor="start">{ TEXT_LIFESPAN_START }</text>
	<text
		x={width - PADDING_GRAPH_RIGHT + 3}
		y={PADDING_AXIS_TOP}
		text-anchor="end">
		<tspan>{ TEXT_LIFESPAN_END[0] }</tspan>
		<tspan class="hide-on-mobile">{ TEXT_LIFESPAN_END[1] }</tspan>
	</text>
	{/if}
	{/if}
</g>

<style type="text/scss">
	@import '../styles/global.scss';

	.axis {
		text {
			font-size: var(--font-size-9);
			fill: var(--color-gray-4);
		}

		line {
			stroke: var(--color-gray-border);
		}
	}
</style>