<script>
	import Navigation from './List-Navigation.svelte';
	import { onMount } from 'svelte';
	/* This Component displays the header of the resulting list of studies */
	import { NUMBER_OF_LISTED_STUDIES, NUMBER_OF_STUDIES, SEARCH_TERM } from '../stores.js';

	// The total number of studies
	let numberOfStudies;
  NUMBER_OF_STUDIES.subscribe(value => { numberOfStudies = value; });

	// All remaining studies
	let numberOfListedStudies;
  NUMBER_OF_LISTED_STUDIES.subscribe(value => {
  	flash();
  	numberOfListedStudies = value;
  });

  let searchInput;
  let isFlashed = false;

  onMount(() => {
    document.addEventListener('keydown', (event) => {
    	const { key, metaKey } = event;
    	if (metaKey && key === 'f') {
    		event.preventDefault();
			  searchInput.focus();
    	}
		});
  });

  function handleKeyDown ({ key }) {
		if (key === 'Escape') {
			SEARCH_TERM.set(null);
			searchInput.blur();
		} else if (key === 'Enter') {
			searchInput.blur();
		}
	}

	function flash () {
		requestAnimationFrame(() => { // instant red bg flash in
			isFlashed = true;

			setTimeout(() => {  // slow 1s fade out
				isFlashed = false;
			}, 1000);
		});
	}
</script>

<header class="list-header">
	<span class="title condensed">Displaying {#if numberOfStudies }<em class:flash={isFlashed}>{ numberOfListedStudies === numberOfStudies ? '' : numberOfListedStudies }</em> { numberOfListedStudies === numberOfStudies ? '' : ' of'}{/if} { numberOfStudies } total projects</span>
	<input
		aria-label="Search within the project list"
		role="search"
		bind:this={searchInput}
		class="search condensed"
		type="search"
		bind:value={$SEARCH_TERM}
		placeholder="Search"
		class:isFiltered={$SEARCH_TERM}
		id="searchInput"
		on:keydown={handleKeyDown} />
	<Navigation />
</header>

<style type="text/scss" scoped>
	@import '../styles/global.scss';

	.list-header {
		padding: 0 var(--spacing-outer);
		display: grid;
		grid-row-gap: var(--spacing-padding);
		grid-template-rows: repeat(3, auto);
		width: 100%;
		grid-template-areas:
			"list-title"
			"list-search"
			"list-navigation";

		@include query($narrow) {
			grid-column-gap: calc(var(--spacing-padding) * 1);
			justify-content: space-evenly;
			grid-template-rows: auto;
      grid-template-columns: 1fr 200px;
      grid-template-areas:
				"list-title list-navigation"
				"list-search list-search";
    }

		@include query($medium) {
			grid-row-gap: 0;
			grid-column-gap: calc(var(--spacing-padding) * 3);
			justify-content: space-evenly;
			grid-template-rows: auto;
      grid-template-columns: 1fr 180px 200px;
      grid-template-areas:
				"list-title list-search list-navigation";
    }

    @include query($wide) {
			padding: 0;
			grid-column-gap: calc(var(--spacing-padding) * 2);
    }

    @include query($extra-wide) {
			grid-column-gap: calc(var(--spacing-padding) * 3);
    }

    .title {
    	grid-area: list-title;

    	em {
			  font-style: normal;
			  transition: color 2s ease;

			  &.flash {
			    color: $color-rat-hover !important;
			    transition: color $transition ease;
			  }
			}
    }

		input.search {
			grid-area: list-search;
			border: none;
			border-bottom: 1px solid var(--color-gray-2);
			border-radius: 0;
			transition: border-color $transition ease;
			color: var(--color-gray-5);
			line-height: 1;

			&:focus {
				outline: none;
				border-bottom-color: var(--color-accent);
			}

			&.isFiltered {
				border-bottom-color: var(--color-accent);
				color: var(--color-gray-5);
			}
		}

		input[type="search"] {
			-webkit-appearance: textfield;
		}

		input[type="search"]::-webkit-search-decoration {
			-webkit-appearance: none;
		}
	}
</style>