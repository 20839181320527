import { SPECIES, PROPERTIES, DAYS_IN_YEAR, DAYS_IN_MONTH, DAYS_IN_WEEK } from '../config.js';
import { scaleLinear } from 'd3-scale';
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import round from 'lodash/round';
import capitalize from 'lodash/capitalize';
import mapValues from 'lodash/mapValues';

export const toDays = function (value, unit) {
  switch (unit) {
    case 'YEAR':
      return value * DAYS_IN_YEAR;
    case 'MONTH':
      return value * DAYS_IN_MONTH;
    case 'WEEK':
      return value * DAYS_IN_WEEK;
    default:
      return value;
  }
}

export const fromDays = function (value, unit) {
  switch (unit) {
    case 'YEAR':
      return value / DAYS_IN_YEAR;
    case 'MONTH':
      return value / DAYS_IN_MONTH;
    case 'WEEK':
      return value / DAYS_IN_WEEK;
    default:
      return value;
  }
}

export const labelTime = function (unit, value, isShort = false) {
  if (isShort) {
    switch (unit) {
      case 'YEAR':
        return 'y';
      case 'MONTH':
        return 'm';
      case 'WEEK':
        return 'w';
      default:
        return 'd';
    }
  }
  switch (unit) {
    case 'YEAR':
      return value === 1 ? 'year' : 'years';
    case 'MONTH':
      return value === 1 ? 'month' : 'months';
    case 'WEEK':
      return value === 1 ? 'week' : 'weeks';
    default:
      return value === 1 ? 'day' : 'days';
  }
}

export const displayAges = (min, max, unit, isShort) => {
  return `${min}&#8239;–&#8239;${max} ${labelTime(unit, max, isShort)}${isShort ? '.' : ''}`
}

// This creates the age scales for all species
const s = mapValues(SPECIES, ({ value, unit, specie }) => {
  return scaleLinear()
    .domain([0, toDays(value, unit)])
    .range([0, 1])
})

export const scaleTime = function (specie, value) {
  if (typeof s[specie] === 'function') {
    return s[specie](value)
  } else {
    return 0
  }
}

export const scaleTimeInvert = function (specie, value) {
  if (typeof s[specie] === 'function') {
    return s[specie].invert(value)
  } else {
    return 0
  }
}

export const isBetween = function (min, max, v) {
  return v >= min && v <= max
}

const FACET_PROPERTIES = PROPERTIES.filter(p => p.isFacet)

export const countPropertiesOfStudies = function (studies, shouldSortByCount = false) {
  const facets = {}

  // First, we create empty array for each property
  forEach(FACET_PROPERTIES, ({ key }) => {
    facets[key] = []
  })

  // Next, we loop over all studies and all properties and push the individual properties into the array
  forEach(studies, (study) => {
    forEach(FACET_PROPERTIES, ({ key }) => {
      const values = get(study, [key])
      if (isArray(values)) {
        forEach(values, (property) => {
          facets[key].push(property)
        })
      } else {
        facets[key].push(values)
      }
    })
  })

  // Finally, we count the occurancies of each item in the arrays
  forEach(FACET_PROPERTIES, ({ label, key }) => {
    const counts = {};
    let max = 0;
    const len = facets[key].length;
    for (let i = 0; i < len; i++) {
      const str = facets[key][i];
      counts[str] = (counts[str] || 0) + 1;
      max = Math.max(max, counts[str])
    }

    // Sort entries by count or alphabetically
    // https://stackoverflow.com/questions/1069666/sorting-object-property-by-values
    const options = shouldSortByCount ? Object.entries(counts).sort(([, a], [, b]) => b - a) : Object.entries(counts).sort(([a], [b]) => a > b)

    facets[key] = {
      label,
      amount: Object.keys(counts).length,
      options,
      max
    }
  })

  // We return a sorted object
  return facets
}

export const displayArray = function (arr) {
  return arr.join(', ');
}

export const between = function (val, min, max) {
  return Math.max(Math.min(val, max), min)
}

export const getSpecieDateFromPercentage = function (specie, percentage) {
  const value = scaleTimeInvert(specie, percentage)
  const unit = get(SPECIES, [specie, 'unit'])
  const formatted = Math.max(round(fromDays(value, unit), 0), 0)
  return `${formatted} ${labelTime(unit, formatted)}`
}

export const formatSpeciesLabel = function (species) {
  return capitalize(species)
}

export const toggleFilter = function (oldFilter, label, facet) {
  const newFilter = [];

  // Are we about to add the option?
  let isAdded = oldFilter.some(([ key, options ]) => {
    return key === facet && options.includes(label)
  });

  const len = oldFilter.length;
  for (let i = 0; i < len; i++) {
    const [key, oldOptions] = oldFilter[i]
    if (key === facet) { // If this facet is the current one
      let newOptions
      if (oldOptions.includes(label)) { // If the option is included in the filter list
        // Remove the element from the list
        oldOptions.splice(oldOptions.indexOf(label),  1)
        newOptions = oldOptions
      } else { // If the option is NOT included in the filter list
        // Add the element to the list
        newOptions = [...oldOptions, label]
      }
      // Add the new list of options to the facet list
      if (newOptions.length) {
        newFilter.push([key, newOptions])
      }
      // We have added the option
      isAdded = true
    } else { // If the facet is NOT the current one
      newFilter.push([key, oldOptions])
    }
  }
  // Did we are already add the option? (Or did we just removed an option)
  if (!isAdded) {
    // Add a new item with just this option to the facet list
    newFilter.push([facet, [label]])
  }
  return newFilter
}

// Remove one facet from the filter list
export const removeFilter = function (oldFilter, facet) {
  const newFilter = [];
  const len = oldFilter.length;
  for (let i = 0; i < len; i++) {
    const [key, oldOptions] = oldFilter[i];
    if (key !== facet) {
      newFilter.push(oldFilter[i]);
    }
  }
  return newFilter;
}

// Add all items to a facet in the filter list
export const addFilter = function (oldFilter, facet, options) {
  const newFilter = removeFilter(oldFilter, facet)
  newFilter.push([facet, options]);
  return newFilter;
}
