<script>
	import { RANGE, IS_FULL_RANGE, LIST_OFFSET } from '../stores.js';
	import { PROPERTIES, PADDING_GRAPH_LEFT, PADDING_GRAPH_RIGHT, PADDING_GRAPH_TOP, PADDING_AXIS_TOP } from '../config.js';
	import Checkbox from '../Helper/Checkbox.svelte';
	import map from 'lodash/map';
	import get from 'lodash/get';
	import { fromDays, labelTime } from '../scripts/utils.js';
  import RangeSlider from "svelte-range-slider-pips";

  export let height;

	let range;
  RANGE.subscribe(value => { range = value; });

  let isFullRange;
  IS_FULL_RANGE.subscribe(value => { isFullRange = value; });

  $: style = `
  	width: calc(100% - ${PADDING_GRAPH_LEFT}px - ${PADDING_GRAPH_RIGHT}px);
  	margin-left: ${PADDING_GRAPH_LEFT}px;
  	top: ${PADDING_GRAPH_TOP / 2 + PADDING_AXIS_TOP / 2 + height + 12}px;
  `

  const onChangeRange = (e) => {
  	const values = get(e, ['detail', 'values'])
  	if (values) {
  		RANGE.set(values);
  		LIST_OFFSET.set(0);
  	}
	}
</script>

<div class="page-selector" class:isFull={isFullRange} style={style}>
	<RangeSlider range values={[0, 100]} on:change={onChangeRange} id="ageRangeSlider" />
</div>

<style type="text/scss">
	@import '../styles/global.scss';

	.page-selector {
		z-index: 0;
		position: absolute;
	}

</style>