<script>
	import { FILTER } from '../stores.js';
	import { toggleFilter } from '../scripts/utils.js';

	export let label;
	export let facet;
	export let isVisible = true;

	let checked;
	FILTER.subscribe(value => {
  	checked = !value.some(([ specie, options ]) => specie === facet && options.includes(label))
  });

	const toggleOption = () => {
		FILTER.update(oldFilter => toggleFilter(oldFilter, label, facet));
	};
</script>

<input
	class="facet-checkbox"
	class:isVisible={isVisible}
	type="checkbox"
	bind:checked={checked}
	on:change={toggleOption}
	id={`checkbox-${label}`} />

<style type="text/scss">
	@import '../styles/global.scss';

	.facet-checkbox {
		display: none;
	}
</style>