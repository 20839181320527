<script>
	import Checkbox from '../Helper/Checkbox.svelte';
	import get from 'lodash/get';

	export let option;
	export let facet;

	$: label = get(option, 0)
	$: amount = get(option, 1)
</script>

<li>
	<label class="pure-material-checkbox" title={`${amount} ${amount === 1 ? 'study has' : 'studies have'} this option`} for="{`checkbox-${label}`}">
		<Checkbox {label} {facet} />
		<div><span class="facet-option">{ label || '—' }</span></div>
	</label>
</li>

<style type="text/scss">
	@import '../styles/global.scss';

	li {
		margin-bottom: 5px;
	}

	:global(.facet-checkbox) {
		&:not(.isVisible) {
			display: none;
		}
	}
</style>