<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="killifish">
  <path class="color-1 stroke" d="M109.5 38.28s44.41-9.94 51.71 39.78H140"/>
  <path class="color-2 stroke" d="M40 107.75s-9.94 44.41 39.78 51.71v-21.22"/>
  <path class="color-1" d="M140.94 129.39S160 90.13 115 45.07C78.24 8.35 39.21 17.72 26 22.45l-.7-2.07s-.5 1-1.19 2.79c-1.76.69-2.79 1.19-2.79 1.19l2.06.7C18.67 38.26 9.29 77.29 46 114c45.06 45.07 84.31 26 84.31 26s-20.49 28.56 1.71 50.75l29.17-29.16 1.32-1.33 29.17-29.17c-22.17-22.19-50.74-1.7-50.74-1.7z"/>
  <path class="color-2" d="M139.56 131.14S147.8 99 102.74 54C66 17.23 38.23 17.19 24.14 23.79c0 0-21.14 48.44 22.21 89.89 46.05 44 81.51 28.79 81.51 28.79s-18.13 26.2 4.06 48.4l26.37-26.37 1.19-1.19L186.2 137c-22.2-22.23-46.64-5.86-46.64-5.86z"/>
  <path d="M140.94 129.39S160 90.13 115 45.07C78.24 8.35 39.21 17.72 26 22.45l-.7-2.07s-.5 1-1.19 2.79c-1.76.69-2.79 1.19-2.79 1.19l2.06.7C18.67 38.26 9.29 77.29 46 114c45.06 45.07 84.31 26 84.31 26s-20.49 28.56 1.71 50.75l29.17-29.16 1.32-1.33 29.17-29.17c-22.17-22.19-50.74-1.7-50.74-1.7zM38.9 37.29L25.03 23.42M161.5 159.9l-15.82-15.82" class="stroke no-fill"/>
  <circle cx="63.09" cy="43.59" r="3.75" class="fill" />
  <path d="M85.46 52.94a47.78 47.78 0 01-56.8 36.6" class="stroke no-fill"/>
</svg>

<style type="text/scss">
	@import '../styles/global.scss';

	svg {
		.color-1, .color-2 {
			transition: fill $transition ease;
		}

		.color-1 {
			fill: $color-killifish-0;
		}

		.color-2 {
			fill: $color-killifish-75;
		}
	}

	:global(.specie-select) {
		&:hover, &:focus {
			#killifish {
				.color-1 {
					fill: $color-killifish-25;
				}

				.color-2 {
					fill: $color-killifish-hover;
				}
			}
		}
	}

	:global(.specie-select.isFilterActive input:not(:checked)) {
		& ~ #killifish {
			.color-1 {
				fill: var(--color-gray-1);
			}

			.color-2 {
				fill: var(--color-gray-2);
			}
		}
	}
</style>