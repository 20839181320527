<script>
  import { SELECTOR_RANGE_AGE, IS_FULL_RANGE, FILTER, IS_FILTER_ACTIVE } from '../stores.js';
  import { PROPERTIES, SPECIES } from '../config.js';
  import Checkbox from '../Helper/Checkbox.svelte';
  import Human from './Human.svelte';
  import Killifish from './Killifish.svelte';
  import Worm from './Worm.svelte';
  import Monkey from './Monkey.svelte';
  import Mouse from './Mouse.svelte';
  import Rat from './Rat.svelte';
  import map from 'lodash/map';
  import capitalize from 'lodash/capitalize';
  import { fromDays, labelTime, toggleFilter } from '../scripts/utils.js';
  import round from 'lodash/round'
  import tooltip from '../scripts/tooltip.js';
  import { onMount } from 'svelte';

  let selectorRangeAge;
  SELECTOR_RANGE_AGE.subscribe(value => { selectorRangeAge = value; });

  let isFullRange;
  IS_FULL_RANGE.subscribe(value => { isFullRange = value; });

  let isFilterActive;
  IS_FILTER_ACTIVE.subscribe(value => {
    isFilterActive = value;
  });

  let filter;
  FILTER.subscribe(value => {
    filter = value
  });

  $: species = map(SPECIES, ({ label, specie, unit }) => {
    const min = Math.max(round(fromDays(selectorRangeAge[specie][0], unit), 0), 0)
    const max = Math.max(round(fromDays(selectorRangeAge[specie][1], unit), 0), 0)
    let el = Human;
    switch (specie) {
      case 'killifish':
        el = Killifish;
        break;
      case 'worm':
        el = Worm;
        break;
      case 'monkey':
        el = Monkey;
        break;
      case 'mouse':
        el = Mouse;
        break;
      case 'rat':
        el = Rat;
        break;
    }
    const isChecked = !filter.some(([ key, options ]) => {
      return key === 'species' && options.includes(specie)
    })
    const tooltipContent = {
      content: `Click to ${isChecked ? 'deselect' : 'select'} ${specie}`,
      followCursor: false,
      theme: 'dark',
      hideOnClick: false
    }
    return {
      label,
      specie,
      min,
      max,
      unit: labelTime(unit, max),
      el,
      tooltipContent,
      isChecked
    }
  })

  onMount(() => {
    document.addEventListener('keydown', (event) => {
      const { key, metaKey, target } = event;
      if (!metaKey && target.id !== 'searchInput') {
        if (key === 'h') {
          event.preventDefault();
          FILTER.update(oldFilter => toggleFilter(oldFilter, 'human', 'species'));
        } else if (key === 'm') {
          event.preventDefault();
          FILTER.update(oldFilter => toggleFilter(oldFilter, 'monkey', 'species'));
        } else if (key === 'r') {
          event.preventDefault();
          FILTER.update(oldFilter => toggleFilter(oldFilter, 'rat', 'species'));
        } else if (key === 'o') {
          event.preventDefault();
          FILTER.update(oldFilter => toggleFilter(oldFilter, 'mouse', 'species'));
        } else if (key === 'k') {
          event.preventDefault();
          FILTER.update(oldFilter => toggleFilter(oldFilter, 'killifish', 'species'));
        } else if (key === 'w') {
          event.preventDefault();
          FILTER.update(oldFilter => toggleFilter(oldFilter, 'worm', 'species'));
        }
      }
    });
  });

  function handleKeyDown (species, event) {
    const { key, target } = event;
    if (key === ' ' || key === 'Enter' || key === 'Spacebar') {
      event.preventDefault();
      FILTER.update(oldFilter => toggleFilter(oldFilter, species, 'species'));
    }
  }
</script>

<div class="page-species">
  <span class="instruction condensed">Select/deselect the species for your project search:</span>
  <ul>
    {#each species as { specie, label, min, max, unit, el, tooltipContent, isChecked}}
    <li>
      <label
        class={`specie-select color-${specie}`}
        class:isFilterActive={isFilterActive}
        class:isChecked={isChecked}
        tabindex="0"
        use:tooltip={tooltipContent}
        for={`checkbox-${specie}`}
        on:keydown={(e) => handleKeyDown(specie, e)}
        >
        <Checkbox label={specie} facet='species' isVisible={false} />
        <svelte:component this={el} />
        <strong class="species-name condensed">{ label }</strong>
        <span class="species-time hide-on-mobile">{ @html (isFullRange ? `Up to ` : `${min}&#8239;–&#8239;`)}{ max }<br />{ unit }</span>
      </label>
    </li>
    {/each}
  </ul>
</div>

<style type="text/scss">
  @import '../styles/global.scss';

  .page-species {
    grid-area: species;
    justify-self: start;
    width: calc(100% - var(--spacing-outer) * 2);
    margin-left: var(--spacing-outer);

    @include query($medium) {
      width: calc(100% - var(--spacing-outer));
      margin-left: var(--spacing-outer);
    }

    ul {
      display: grid;
      justify-items: stretch;
      justify-content: stretch;
      grid-auto-flow: column;
      list-style: none;
      grid-column-gap: 15px;
      grid-template-columns: repeat(6, 1fr);

      li {
        label {
          display: grid;
          justify-items: center;
          grid-auto-flow: row;
          cursor: pointer;

          &:focus {
            outline: none;
          }

          .species-name, .species-time {
            color: currentColor;
            transition: color $transition ease;
            text-align: center;
          }

          .species-name {
            display: block;
            margin-top: 10px;
            margin-bottom: 5px;
          }

          &:not(.isChecked) {
            .species-name, .species-time {
              color: var(--color-gray-3) !important;
            }
          }
        }
      }
    }
  }

  :global(.specie-select svg) {
    width: 80%;
  }
</style>