<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="worm">
  <path class="color-1" d="M133.92 186.87a14.1 14.1 0 01-8.74-25.17c36.59-28.85 25.83-51.09 24.49-53.53-3.68-6.71-9.08-14.65-15-16.29-7-1.91-17.64 4.38-28 10.47-15.07 8.9-33.82 20-53.6 11.73a38.47 38.47 0 01-21.75-22.44c-10.23-27.24 7.76-65.2 9.85-69.44a14.1 14.1 0 0125.3 12.45c-5.78 11.85-13.27 35.1-8.74 47.1a10.19 10.19 0 006.17 6.3c6.74 2.81 17.27-3.4 28.42-10 14.68-8.63 31.29-18.44 49.78-13.36 12.55 3.46 23 13.06 32 29.35 9.22 15.5 14.32 53.72-31.43 89.8a14.06 14.06 0 01-8.75 3.03z"/>
  <path class="color-2" d="M126.31 186.07c-9.58-6.94-7.25-18.23-1.13-23 36.59-28.85 25.83-51.09 24.49-53.53-3.68-6.71-9.08-14.65-15-16.29-7-1.91-17.64 4.38-28 10.47-15.07 8.9-33.82 20-53.6 11.73A38.47 38.47 0 0131.32 93c-10.23-27.24 7.76-65.2 9.85-69.43 3.44-7 12.14-9.21 21.39-4.25 0 0-26.09 40.95-16.84 66.38s34.35 13.21 42.93 7.6 34.68-20.81 48.88-18.17 38.65 23.12 31.05 57.14c-7.88 35.25-42.27 53.8-42.27 53.8z"/>
  <path class="stroke no-fill" d="M133.1 188.19a14.1 14.1 0 01-8.74-25.19c36.59-28.85 25.84-51.09 24.49-53.53-3.67-6.71-9.08-14.65-15-16.29-7-1.91-17.64 4.38-28 10.47-15.06 8.9-33.82 20-53.59 11.73A38.44 38.44 0 0130.51 93c-10.23-27.24 7.76-65.2 9.84-69.43A14.1 14.1 0 0165.66 36c-5.79 11.85-13.28 35.1-8.75 47.1a10.22 10.22 0 006.17 6.3c6.75 2.81 17.28-3.4 28.43-10 14.66-8.65 31.28-18.46 49.77-13.37 12.55 3.45 23 13 32 29.34 9.23 15.5 14.32 53.73-31.43 89.8a14 14 0 01-8.75 3.02z"/>
</svg>

<style type="text/scss">
	@import '../styles/global.scss';

	svg {
		.color-1, .color-2 {
			transition: fill $transition ease;
		}

		.color-1 {
			fill: $color-worm-0;
		}

		.color-2 {
			fill: $color-worm-75;
		}
	}

	:global(.specie-select) {
		&:hover, &:focus {
			#worm {
				.color-1 {
					fill: $color-worm-25;
				}

				.color-2 {
					fill: $color-worm-hover;
				}
			}
		}
	}

	:global(.specie-select.isFilterActive input:not(:checked)) {
		& ~ #worm {
			.color-1 {
				fill: var(--color-gray-1);
			}

			.color-2 {
				fill: var(--color-gray-2);
			}
		}
	}
</style>