<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="mouse">
  <path class="color-1" d="M37 79.21s6.51 39.61-9.7 39.61h20s6.46 45.9 16.16 55.6c0 0-24.57-5.17-24.57 16.81h99.61s28.44 3.23 28.44-44.61-51.07-50.42-51.07-50.42-41.38-5.1-41.38-29.76h-.27c2.65-.54 8.58-1.1 12.71-3.15C98 57.79 102.15 44.94 97 36.1s-17.91-11-28.6-4.84C64 33.81 60.56 38.48 57 46l-.22.44H57L11 28.13c0-.88-4.13 9.93 2.38 28.38C18.07 69.75 26 80 50.57 80"/>
  <path class="stroke no-fill" d="M142.14 191.55s49.92 1.34 49.92-42.69V75.32"/>
  <path class="color-2" d="M115.87 96.2s-41.38-5.1-41.38-29.76h-.27l-.74-.88a22 22 0 009.22-4c8.22-6 11-16.14 6.31-22.58s-15.22-6.79-23.43-.76a21.77 21.77 0 00-7.33 9.19L57 46l-.19.38L19 31.83c0-.88-5.62 3.63-1 24.68 3 13.71 13.87 23.21 33.35 23.21l-.35-4.3s6.15 80.68 27.09 105.36c0 0-29.73-6.6-29.73 10.45h90.14s28.44 3.23 28.44-44.61-51.07-50.42-51.07-50.42z"/>
  <path class="stroke no-fill" d="M36.9 79.72s6.52 39.61-9.7 39.61h20.05s6.46 45.9 16.16 55.6c0 0-24.57-5.18-24.57 16.8h99.56s28.45 3.24 28.45-44.6-51.08-50.43-51.08-50.43S74.4 91.61 74.4 67h-.28c2.65-.55 8.59-1.1 12.72-3.16 11-5.5 15.22-18.35 10.11-27.19S79 25.59 68.34 31.76C63.92 34.31 60.47 39 57 46.47l-.22.44h.21l-46-18.28c0-.89-4.14 9.92 2.37 28.37C18 70.25 25.91 80.54 50.48 80.54" />
  <circle class="fill" cx="35.7" cy="54.23" r="3.88" />
</svg>

<style type="text/scss">
	@import '../styles/global.scss';

	svg {
		.color-1, .color-2 {
			transition: fill $transition ease;
		}

		.color-1 {
			fill: $color-mouse-0;
		}

		.color-2 {
			fill: $color-mouse-75;
		}
	}

	:global(.specie-select) {
		&:hover, &:focus {
			#mouse {
				.color-1 {
					fill: $color-mouse-25;
				}

				.color-2 {
					fill: $color-mouse-hover;
				}
			}
		}
	}

	:global(.specie-select.isFilterActive input:not(:checked)) {
		& ~ #mouse {
			.color-1 {
				fill: var(--color-gray-1);
			}

			.color-2 {
				fill: var(--color-gray-2);
			}
		}
	}
</style>