import { writable, readable, derived } from 'svelte/store';
import { SEARCH_KEYS, PATH_DATA_STUDIES, MAPPINGS, SPECIES, PROPERTIES, PATH_DATA_LIFESPANS, ITEMS_PER_PAGE, DEFAULT_SORT_FUNCTIONS, KEY_STUDY_ID } from './config.js';
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';
import last from 'lodash/last';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import isUndefined from 'lodash/isUndefined';
import round from 'lodash/round';
import mapValues from 'lodash/mapValues';
import MiniSearch from 'minisearch';
import { scaleTime, toDays, scaleTimeInvert, isBetween, countPropertiesOfStudies } from './scripts/utils.js';

export const SPECIES_KEYS = map(SPECIES, ({ specie }) => specie);

const miniSearch = new MiniSearch({
  idField: KEY_STUDY_ID,
  storeFields: [KEY_STUDY_ID],
  fields: SEARCH_KEYS, // fields to index for full-text search
  searchOptions: {
    fuzzy: 0.1,
    prefix: true
  }
})

// The list of studies. We fetch the data from a csv file and parse it
export const STUDIES = readable([], async (set) => {
  const res = await fetch(PATH_DATA_STUDIES);
  const json = await res.json();
  const data = json.map((datum, id) => {
    const unit = get(MAPPINGS, get(datum, 'unit'));
    const species = get(datum, 'species').toLowerCase();

    // The search functionality can be speed up when we prepare the input.
    // So we create the searachable keys and append them to the object
    let search = {};
    SEARCH_KEYS.forEach(key => {
      let value = get(datum, key, '');
      if (isArray(value)) { value = value.join(' ') }
      search[`${key}-prepared`] = value;
    })

    const { min, max } = datum;
    const minInDays = toDays(min, unit);
    const maxInDays = toDays(max, unit);
    const minInPercent = scaleTime(species, minInDays);
    const maxInPercent = scaleTime(species, maxInDays);

    return {
      [KEY_STUDY_ID]: id, // This is a simple running up number
      ...datum,
      unit,
      speciesID: SPECIES_KEYS.indexOf(species),
      minInDays,
      maxInDays,
      minInPercent,
      maxInPercent,
      species,
      ...search
    }
  })
  set(data);
});

// The total number of studies
export const NUMBER_OF_STUDIES = derived(STUDIES, ($studies) => {
  return $studies.length || 0;
})

// List of active/hoverd studies
export const HOVER = writable(null);

// This holds the current search term
export const SEARCH_TERM = writable(null);

// These values define 0 and 100 percent of the age ranges
const MIN_AGE_RANGE = 0;
const MAX_AGE_RANGE = 100;

// Stores the age range selection in percentage
export const RANGE = writable([MIN_AGE_RANGE, MAX_AGE_RANGE]);

// Checks if the full age range is selected
export const IS_FULL_RANGE = derived(RANGE, ($range) => {
  return $range[0] === MIN_AGE_RANGE && $range[1] === MAX_AGE_RANGE;
})

// This holds the list of filters
export const FILTER = writable([]);

// This switch defines if the filtering is active
export const IS_FILTER_ACTIVE = writable(false);

export const ACTIVE_SPECIES = derived(FILTER, ($filter) => {
  const speciesFilter = $filter.find(f => f[0] === 'species');
  if (typeof speciesFilter === 'undefined') {
    return SPECIES_KEYS;
  }
  const filteredSpecies = speciesFilter[1];
  const remainingSpecies = SPECIES_KEYS.filter((species) => {
    return !filteredSpecies.includes(species);
  });
  return remainingSpecies;
})

export const SORT_KEY = writable(null);
export const SORT_ASC = writable(true);

function moveKeysToFront (keys = []) {
  let arr = DEFAULT_SORT_FUNCTIONS;
  for (let i = 0; i < keys.length; i++) {
    arr = arr.filter(item => item !== keys[i]);
    arr.unshift(keys[i]);
  }
  return arr;
}

// Depening on which key the user chooses to sort the table, we rearrange the array of the keys
export const SORT_FUNCTION = derived([SORT_KEY], ([$sort_key]) => {
  switch ($sort_key) {
    case 'name':
      return moveKeysToFront(['name']);
    case 'species':
      return moveKeysToFront(['speciesID']);
    case 'pi':
      return moveKeysToFront(['pi']);
    case 'sex':
      return moveKeysToFront(['sex']);
    case 'experiment':
      return moveKeysToFront(['experiment']);
    case 'ages':
      return moveKeysToFront(['maxInPercent', 'minInPercent']);
    case 'region':
      return moveKeysToFront(['region']);
    case 'genes':
      return moveKeysToFront(['genes']);
    default:
      return moveKeysToFront([]);
  }
})

export const SELECTOR_RANGE_AGE = derived([RANGE], ([$range]) => {
  return mapValues(SPECIES, (obj, specie) => {
    return [
      scaleTimeInvert(specie, $range[0] / 100),
      scaleTimeInvert(specie, $range[1] / 100)
    ]
  })
});

// The remaining studies after filter apply
// We create a new array only consisting of 0, 1 or 2. This is quicker to work with than the whole list of studies
// We have three four cases and three stati:
// 1. Is excluded by the facets (value: 0)
// 2. Is active because there is no hover bar (value: 2)
// 3. Is hovered by the bar (value: 2)
// 4. Is not hovered by the bar (value: 1)
export const ACTIVE_STUDIES = derived([SELECTOR_RANGE_AGE, STUDIES, FILTER, IS_FULL_RANGE, IS_FILTER_ACTIVE], ([$selector_range_age, $studies, $filter, $is_full_range, $is_filter_active]) => {
  const activeStudies = $studies.filter((study) => {
    const { minInDays, maxInDays, species } = study
    // First, we filter the list through the selected filters from the facets
    // If any of the options is found in the properties, the study is excluded
    if ($is_filter_active) {
      if ($filter.some(([facet, options]) => {
        const property = get(study, facet);
        if (isUndefined(property)) {
          console.warn(`Filtering by unknown facet ${facet}. This key is not present in the study.`);
          return false;
        }
        if (isString(property)) {
          return options.includes(property);
        }
        if (isArray(property)) {
          return property.every(p => options.includes(p))
        }
        // We loop over the disabled options from the filter if the facet is found in the study
        // and check if any of these options is included in the options/properties of the study
        return true
      })) { return false; }
    }

    // Then we check if the study is within the selected age range
    if (
      $is_full_range ||
      (
        (
          $selector_range_age[species][0] <= minInDays &&
          $selector_range_age[species][1] >= minInDays
        ) || (
          $selector_range_age[species][0] <= maxInDays &&
          $selector_range_age[species][1] >= maxInDays
        ) || (
          $selector_range_age[species][0] >= minInDays &&
          $selector_range_age[species][1] <= maxInDays
        )
      )
    ) { return true; }
    return false;
  })
  // It’s more efficient to remove all previous studies and add all remaining studies to the search
  miniSearch.removeAll();
  miniSearch.addAll(activeStudies);
  return activeStudies;
});

// This is used for the library fuzzysearch
const SEARCH_KEY_PREPARED = SEARCH_KEYS.map(key => `${key}-prepared`);

// After filtering the studies by facets, we search through the remaining items
export const RESULTS = derived([SEARCH_TERM, ACTIVE_STUDIES, STUDIES], ([$search_term, $active_studies, $all_studies]) => {
  if ($active_studies.length && $search_term) {
    // If we have a search term and a list of active studies, we start the search
    // Since our search function only searches through the list of active studies, the results are already filtered in that way
    // The search function return the study id which we then use to find the responding study in the list of all studies
    // We need to do that because the search function does not return all keys that we need for the list
    // Since the study id is simply a number going up, it matches the natural order of the studies
    // It could be (for unknown reason), that the one study could not be found. That’s why we use get and then filter the list
    return miniSearch.search($search_term).map(({ [KEY_STUDY_ID]: id }) => get($all_studies, id)).filter(Boolean);
  }
  // If there is no search term, we simply return the list of all active (filtered through facets) studies
  return $active_studies;
})

export const ACTIVE_STUDIES_KEYS = derived([RESULTS], ([$results]) => {
  return $results.map(({ [KEY_STUDY_ID]: id }) => id);
})

export const HAS_ACTIVE_STUDIES = derived([NUMBER_OF_STUDIES, RESULTS], ([$number_of_studies, $results]) => {
  return $results.length !== 0;
})

// The list of studies currently displayed after filters applied
export const LISTED_STUDIES = derived([RESULTS, STUDIES, SORT_FUNCTION, SORT_ASC], ([$results, $studies, $sort_func, $sort_asc]) => {
  // First we filter the studies
  // const listedStudies = $results
  // Second, we sort the list by a specific array of keys
  const sortedStudies = sortBy($results, $sort_func);
  // Last, we check in which way the sorting is selected
  return $sort_asc ? sortedStudies : reverse(sortedStudies);
});

// The number of remainding studies after filters applied
export const NUMBER_OF_LISTED_STUDIES = derived(LISTED_STUDIES, ($listed_studies) => {
  return $listed_studies.length || 0;
})

// The total number of pages
export const NUMBER_OF_PAGES = derived([NUMBER_OF_LISTED_STUDIES], ([$listed_listed_studies]) => {
  return Math.ceil($listed_listed_studies / ITEMS_PER_PAGE);
});

// This count is not really displayed in the interface. This is why we currently do not sort it.
export const FACETS_ALL = derived(STUDIES, ($studies) => {
  return countPropertiesOfStudies($studies, false);
});

// This is currently not displayed in the interface
export const FACETS_FILTERED = derived(LISTED_STUDIES, ($listedStudies) => {
  return countPropertiesOfStudies($listedStudies, false);
});

export const FACETS = derived([FACETS_ALL, FACETS_FILTERED], ([$facets_all, $facets_filtered]) => {
  return map($facets_all, (facet, id) => {
    const optionsFiltered = get($facets_filtered, [id, 'options']);
    const optionsRegular = get(facet, ['options']);
    const options = map(optionsRegular, ([key, value]) => {
      return [key, value, get(optionsFiltered, key, 0)];
    })
    return {
      id,
      facet: {
        ...facet,
        options
      }
    }
  })
});

// Page offset for navigating the study list
export const LIST_OFFSET = writable(0);

// This generates the lifespan data for the chart
export const LIFESPANS = readable([], async (set) => {
  const res = await fetch(PATH_DATA_LIFESPANS);
  const json = await res.json()
  const lifespans = sortBy(json.map(({ specie, unit, data }) => {
    const endOfLife = last(data)[0]
    const datum = data.map(([time, alive]) => {
      // We convert the value to percentage and return it together with the time value and how many are alive
      return [
        round(1 / endOfLife * time, 2), // Where in the life are we from 0…1 percentage
        round(alive / 100, 2), // How many are alive in percentage 0…1
        time // Where in life are we in absolute numbers
      ]
    })
    return {
      speciesID: SPECIES_KEYS.indexOf(specie),
      specie,
      unit,
      data: datum
    }
  }), 'speciesID')
  set(lifespans);
})

// This generates the values visible in the tooltip when hovering over the chart
export const HOVER_ALIVE_VALUES = derived([HOVER, LIFESPANS], ([$hover, $lifespans]) => {
  return $lifespans.map(({ data, specie, unit}) => {
    let alive = 1; // The default rate is 100 percent
    const l = data.length;
    // We loop over all alive values and break if the time value is bigger.
    // This way we return the value before is gets too big.
    for (let i = 0; i < l; i++) {
      const [percent, value] = data[i]
      if (percent > $hover) {
        break;
      }
      alive = value;
    }
    return {
      specie,
      unit,
      alive: round(alive * 100, 0)
    }
  })
});

// Every time the filter is changed, we need to reset the list offset and optinally set the filter switch to true
FILTER.subscribe(value => {
  if (value.length) {
    IS_FILTER_ACTIVE.set(true);
  }
  LIST_OFFSET.set(0);
});

// Every time the search term changes, we reset the list offset
SEARCH_TERM.subscribe(value => {
  LIST_OFFSET.set(0);
});

