<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="human">
  <path d="M76.1 114.73c0 12.49-14.66 31.22-33.4 39.74s-19.89 35.78-19.89 35.78h153.38s-1.14-27.25-19.89-35.78-33.63-28.24-33.63-40.73" class="stroke color-2"/>
  <path d="M138.42 81.48h1.65a20 20 0 0020.14-19.82V59.6h.05V44.27c-14.82 0-31.56-21.73-31.56-21.73a38.91 38.91 0 00-68.39 25.37v34.41a38.91 38.91 0 0038.91 38.91h.22a38.91 38.91 0 0038.91-38.91" class="stroke color-2"/>
  <path class="stroke color-1" d="M60.31 47.13L83 30.18s13.48 25.19 55.22 29.5v22.64a38.91 38.91 0 01-77.82 0V47.91" />
  <circle cx="84.16" cy="67.56" r="3.41" class="fill"/>
  <circle cx="114.84" cy="67.56" r="3.41" class="fill"/>
  <path d="M98.93 73.8v14.77" class="stroke"/>
  <path d="M137.53 59.74h21.8" class="stroke color-2"/>
  <path d="M115.47 97a20.65 20.65 0 01-31.25.26" class="no-fill stroke"/>
</svg>

<style type="text/scss">
	@import '../styles/global.scss';

	svg {
		.color-1, .color-2 {
			transition: fill $transition ease;
		}

		.color-1 {
			fill: $color-human-0;
		}

		.color-2 {
			fill: $color-human-75;
		}
	}

	:global(.specie-select) {
		&:hover, &:focus {
			#human {
				.color-1 {
					fill: $color-human-25;
				}

				.color-2 {
					fill: $color-human-hover;
				}
			}
		}
	}

	:global(.specie-select.isFilterActive input:not(:checked)) {
		& ~ #human {
			.color-1 {
				fill: var(--color-gray-1);
			}

			.color-2 {
				fill: var(--color-gray-2);
			}
		}
	}
</style>