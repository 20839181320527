<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="rat">
  <path class="color-1" d="M191.92 25.2h-51.08A32.53 32.53 0 00126 10.81c-12-5.36-25-2.4-29 6.59s2.46 20.63 14.45 26A29.76 29.76 0 00124 46c-9.59 10-37 27.29-37 27.29S38 102 37.56 144.77c-.45 46.24 26.6 44 26.6 44h96.24c0-21.25-22.85-17.15-22.85-17.15 23.1-25.76 15.62-76.45 15.62-76.45h27.74c-23.78 0-17.74-38.76-17.74-38.76l-3.63.35c18.1-3.52 29.16-16.48 32.38-23.46 3.75-8.1 0-8.1 0-8.1z"/>
  <path class="color-2" d="M184 25.2h-42.28l-1.45 1.8a26.36 26.36 0 00-11.09-9.83c-10.43-5.12-21.8-3.34-25.39 4s2 17.39 12.4 22.51a29 29 0 008.39 2.63c-9.79 10-36.77 27-36.77 27S38.86 102 38.44 144.77C38 191 65 188.79 65 188.79h85.67c0-17.37-29.47-12.3-29.47-12.3C154.93 139.28 151 56.78 151 56.78c25.1 0 31.12-16.48 34.34-23.46 3.72-8.12-1.34-8.12-1.34-8.12z"/>
  <circle class="fill" cx="164.17" cy="35.88" r="3.75" />
  <path class="stroke no-fill" d="M61 188.61s-47.85-.89-47.85-45.81V73.72" />
  <path class="stroke no-fill" d="M191.92 25.2h-51.08A32.53 32.53 0 00126 10.81c-12-5.36-25-2.4-29 6.59s2.46 20.63 14.45 26A29.76 29.76 0 00124 46c-9.59 10-37 27.29-37 27.29S38 102 37.56 144.77c-.45 46.24 26.6 44 26.6 44h96.24c0-21.25-22.85-17.15-22.85-17.15 23.1-25.76 15.62-76.45 15.62-76.45h33.47c-29.51 0-23.47-38.76-23.47-38.76l-11.76 1.38c21.57 0 37.29-17.51 40.51-24.49 3.75-8.1 0-8.1 0-8.1z" />
</svg>

<style type="text/scss">
	@import '../styles/global.scss';

	svg {
		.color-1, .color-2 {
			transition: fill $transition ease;
		}

		.color-1 {
			fill: $color-rat-0;
		}

		.color-2 {
			fill: $color-rat-75;
		}
	}

	:global(.specie-select) {
		&:hover, &:focus {
			#rat {
				.color-1 {
					fill: $color-rat-25;
				}

				.color-2 {
					fill: $color-rat-hover;
				}
			}
		}
	}

	:global(.specie-select.isFilterActive input:not(:checked)) {
		& ~ #rat {
			.color-1 {
				fill: var(--color-gray-1);
			}

			.color-2 {
				fill: var(--color-gray-2);
			}
		}
	}
</style>