<script>
  import { ACTIVE_STUDIES_KEYS, HOVER, ACTIVE_SPECIES, STUDIES, ACTIVE_STUDIES, SPECIES_KEYS, HAS_ACTIVE_STUDIES, HOVER_ALIVE_VALUES } from '../stores.js';
  import { PADDING_AXIS_TOP, PADDING_GRAPH_LEFT, PADDING_GRAPH_RIGHT, DEFAULT_SORT_FUNCTIONS } from '../config.js';
  import { scaleTime, scaleTimeInvert, fromDays, labelTime, displayArray, displayAges } from '../scripts/utils.js';
  import Axis from '../Helper/Axis.svelte';
  import { scaleLinear, scalePoint } from 'd3-scale';
  import tooltip from '../scripts/tooltip.js';
  import map from 'lodash/map';
  import forEach from 'lodash/forEach';
  import uniq from 'lodash/uniq';
  import isNaN from 'lodash/isNaN';
  import isNull from 'lodash/isNull';
  import get from 'lodash/get';
  import capitalize from 'lodash/capitalize';

  let width;
  let height;

  let studies;
  STUDIES.subscribe(value => { studies = value; });

  let hover;
  HOVER.subscribe(value => { hover = value; });

  let activeSpecies;
  ACTIVE_SPECIES.subscribe(value => { activeSpecies = value; });

  let hasActiveStudies;
  HAS_ACTIVE_STUDIES.subscribe(value => { hasActiveStudies = value; });

  let activeStudiesKeys;
  ACTIVE_STUDIES_KEYS.subscribe(value => { activeStudiesKeys = value; });

  let hoverDeathValues;
  HOVER_ALIVE_VALUES.subscribe(value => { hoverDeathValues = value; });

  const padding = {
    top: 40,
    bottom: 20
  }

  // We just need three elements for the grouping. More elements would result in too many rows.
  const STUDY_SORTING_KEYS = DEFAULT_SORT_FUNCTIONS.slice(0, 3);
  // In order to add the correct labels, we need to find out where the species id is in the sorting string
  const SPECIES_ID_POSITION = DEFAULT_SORT_FUNCTIONS.indexOf('speciesID');
  const SORTING_KEY_SYMBOL = '-';

  const studyPlacement = (study) => {
    return STUDY_SORTING_KEYS.map(key => get(study, key, '')).join(SORTING_KEY_SYMBOL);
  };

  $: listOfStudies = uniq(map(studies, studyPlacement)).sort()

  $: y = scalePoint()
    .domain(listOfStudies)
    .range([padding.top, height - padding.bottom])

  $: widthPadded = width - PADDING_GRAPH_LEFT - PADDING_GRAPH_RIGHT;

  $: lines = map(studies, (study, i) => {
    const { name, pi, studyID, species, unit, minInDays, maxInDays, min, max, speciesID, experiment, minInPercent, maxInPercent } = study
    const start = minInPercent * widthPadded + PADDING_GRAPH_LEFT
    const end = maxInPercent * widthPadded + PADDING_GRAPH_LEFT
    return {
      x1: start,
      x2: end,
      y: y(studyPlacement(study)),
      studyID,
      species,
      min,
      max,
      tooltipOptions: {
        content:
          `<dl class="study"><dt>${name}</dt><dd>${capitalize(species)}</dd><dd>${displayArray(experiment)}</dd><dd>${displayAges(min, max, unit, false)}</dd></dl>`,
        allowHTML: true
      }
    }
  })

  function getLabels (studies, species) {
    let currentCategory = null;
    return map(studies, (row) => {
      let isFirstInCategory = false;
      const species = SPECIES_KEYS[row.split(SORTING_KEY_SYMBOL)[SPECIES_ID_POSITION]]
      if (currentCategory !== species) {
        isFirstInCategory = true;
        currentCategory = species;
      }
      return {
        x: 0,
        y: y(row),
        isFirstInCategory,
        label: capitalize(species),
        species,
        isActive: species.includes(species)
      }
    })
  }

  $: labels = getLabels(listOfStudies, activeSpecies)

  function highlightStudy (study = null) {
    HOVER.set(study)
  }
</script>

<div class="hide-on-mobile page-graph" bind:clientWidth={width} bind:clientHeight={height}>
  <svg>
    <text x="0" text-anchor="start" y={padding.top - 26} class="title condensed" dominant-baseline="hanging">
      Projects
    </text>
    <g>
      {#each labels as { x, y, species, label, isFirstInCategory, isActive }}
        {#if isFirstInCategory}
        <text
          x={x}
          y={y}
          dominant-baseline="middle"
          class={`species color-${species}`}
          class:isActive={isActive}>
          {label}
        </text>
        {/if}
        <line x1={PADDING_GRAPH_LEFT} x2={width - PADDING_GRAPH_RIGHT} y1={y} y2={y} class="tick" />
      {/each}
    </g>
    <g>
      {#each lines as { studyID, species, min, max, x1, x2, y, isActive, studyID, tooltipOptions }, i}
      {#if !isNaN(x1) && !isNaN(x2) && !isNaN(y)}
      <g class:hasHover={hover !== null}>
        <line
          x1={x1}
          x2={x2}
          y1={y}
          y2={y}
          class="study trigger"
          use:tooltip={tooltipOptions}
          on:mouseenter={() => highlightStudy(studyID)}
          on:mouseleave={() => highlightStudy()} />
        <line
          x1={x1}
          x2={x2}
          y1={y}
          y2={y}
          class="{`study ${species}`}"
          class:isActive={(hover === null && activeStudiesKeys.includes(studyID)) || (hover !== null && hover == studyID)} />
      </g>
      {/if}
      {/each}
    </g>
    <Axis height={height} width={width} />
  </svg>
</div>

<style type="text/scss">
  @import '../styles/global.scss';

  .page-graph {
    grid-area: graph;
    z-index: 2;
    height: $height-graph;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  text.species {
    font-size: var(--font-size-9);
    transition: fill $transition ease;

    &:not(.isActive) {
      fill: var(--color-gray-2) !important;
    }
  }

  line.tick {
    stroke: var(--color-gray-border);
    stroke-dasharray: 2
  }

  line {
    &.study {
      &:not(.trigger) {
        transition: stroke $transition ease;
        stroke-width: 3px;
        vector-effect: non-scaling-stroke;
        pointer-events: none;

        &.human { stroke: $color-human-100; }
        &.monkey { stroke: $color-monkey-100; }
        &.mouse { stroke: $color-mouse-100; }
        &.rat { stroke: $color-rat-100; }
        &.killifish { stroke: $color-killifish-100; }
        &.worm { stroke: $color-worm-100; }

        &:not(.isActive) {
          stroke: var(--color-gray-2) !important;
        }
      }

      &.trigger {
        vector-effect: non-scaling-stroke;
        stroke-width: 7px;
        stroke: transparent;
        opacity: 0;
      }
    }
  }
</style>