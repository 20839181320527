<script>
	/* This Component displays the resulting list of studies */
	import { NUMBER_OF_STUDIES, HOVER, LISTED_STUDIES, LIST_OFFSET, NUMBER_OF_LISTED_STUDIES, SORT_KEY, SORT_ASC } from '../stores.js';
	import { ITEMS_PER_PAGE, PROPERTIES } from '../config.js';
	import { labelTime, displayArray, displayAges } from '../scripts/utils.js';
	import tooltip from '../scripts/tooltip.js';
	import capitalize from 'lodash/capitalize';

	const tooltipProps = {
		followCursor: false,
		theme: 'dark',
		hideOnClick: false
	}

	// The total number of studies
	let numberOfStudies;
  NUMBER_OF_STUDIES.subscribe(value => { numberOfStudies = value; });

	// All remaining studies
	let listedStudies;
  LISTED_STUDIES.subscribe(value => { listedStudies = value; });

  let hover;
  HOVER.subscribe(value => { hover = value; });

  // All remaining studies
	let numberOfListedStudies;
  NUMBER_OF_LISTED_STUDIES.subscribe(value => { numberOfListedStudies = value; });

  // The offset for navigating the list
  let offset;
  LIST_OFFSET.subscribe(value => { offset = value; });

  // The offset for navigating the list
  let sortKey;
  SORT_KEY.subscribe(value => { sortKey = value; });

  // The offset for navigating the list
  let sortAsc;
  SORT_ASC.subscribe(value => { sortAsc = value; });

  const COLUMNS = [{
  	label: 'Species',
  	key: 'species',
  	value: (node) => capitalize(node.species)
  }, {
  	label: 'Experiment',
  	key: 'experiment',
  	value: (node) => displayArray(node.experiment),
  	tooltip: (node) => `${node['experiment-original']}`
  }, {
  	label: 'Ages',
  	key: 'ages',
  	value: (node) => displayAges(node.min, node.max, node.unit, true)
  }, {
  	label: 'Brain Region',
  	key: 'region',
  	value: (node) => displayArray(node.region),
  	tooltip: (node) => `${node['region-original']}`
  }, {
  	label: 'Genes',
  	key: 'genes',
  	value: (node) => displayArray(node.genes)
  }, {
  	label: 'Sex',
  	key: 'sex',
  	value: (node) => node.sex
  }, {
  	label: 'PI',
  	key: 'pi',
  	value: (node) => node.pi,
  	tooltip: (node) => `Investigator: ${node.pi}; Parent study leader: ${node.spi}`
  }, {
  	label: 'Parent study',
  	key: 'name',
  	value: (node) => node.name
  }]

  // The list of studies to be shown
  $: list = listedStudies
  	.slice(offset, offset + ITEMS_PER_PAGE)
  	.map((node, index) => {
  		return {
  			cells: COLUMNS.map(({ value: valueFunc, tooltip: tooltipFunc, key }) => {
  				const content = valueFunc(node)
  				let cell = {
  					cellContent: content || '—',
  					isMuted: !Boolean(content),
  					key
  				}
  				let tooltip = {
  					trigger: 'manual',
  					followCursor: false
  				}
  				if (tooltipFunc) {
  					const tooltipContent = tooltipFunc(node)
  					if (tooltipContent) {
  						tooltip = {
  							...tooltip,
  							content: tooltipContent,
  							trigger: 'mouseenter focus'
  						}
  					}
  				}
  				cell['tooltipContent'] = tooltip
  				return cell
  			}),
  			species: node.species,
  			id: node.studyID,
  			url: node.url,
  			index: index + offset + 1
  		}
  	})

  function sortList (key) {
  	if (key === sortKey) {
  		SORT_ASC.update(v => !v);
  	} else {
  		SORT_KEY.set(key);
  		SORT_ASC.set(true);
  	}
	}

	function handleKeyDown (link, event) {
		const { key, target } = event;
		if (key === ' ' || key === 'Enter' || key === 'Spacebar') {
			event.preventDefault();
			openLink(link);
		} else if (key === 'ArrowDown') {
			const next = target.nextSibling;
			if (next) {
				event.preventDefault();
				next.focus();
			}
		} else if (key === 'ArrowUp') {
			const previous = target.previousSibling;
			if (previous) {
				event.preventDefault();
				previous.focus();
			}
		} else if (key === 'Escape') {
			target.blur();
		}
	}

	function openLink (link) {
		window.open(link, '_blank');
		// window.location = link;
	}

	function highlightStudy (study = null) {
		HOVER.set(study)
	}
</script>

<div class="list-info">
	<span class="condensed">Swipe horizontally and vertically to view the whole table.</span>
</div>

<div class="list-list">
	<table role="feed" aria-busy={!Boolean(numberOfStudies)}>
		<thead>
			<tr>
				<th class="speciesBar"></th>
				{#each COLUMNS as { label, key }}
				<th
					on:click={() => sortList(key)}
					use:tooltip={{ ...tooltipProps, content: `Click to sort by ‘${label}’ in ${sortKey === key && sortAsc ? 'descending' : 'ascending'} order` }}
					class={key}
					class:active={sortKey === key}
					class:asc={sortKey === key && sortAsc}>
					<div>
						{ label }
						<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-switch-vertical" width="16" height="16" viewBox="0 0 20 20" stroke-width="0" stroke-linecap="round" stroke-linejoin="round">
							<path stroke="none" d="M0 0h12v12H0z" fill="none"></path>
	  					<path d="M14.5 13L10 18l-4.5-5z" class="asc" />
  						<path d="M14.5 7L10 2 5.5 7z" class="desc" />
						</svg>
					</div>
				</th>
				{/each}
			</tr>
		</thead>
		<tbody>
			{#if numberOfListedStudies}
				{#each list as { species, cells, id, url, index }}
				<tr
					class={species}
					class:isHover={hover !== null && hover === id}
					on:mouseenter={() => highlightStudy(id)}
					on:focus={() => highlightStudy(id)}
					on:mouseleave={() => highlightStudy()}
					on:blur={() => highlightStudy()}
					on:click={() => openLink(url)}
					on:keydown={(e) => handleKeyDown(url, e)}
					title="Open study website in a new tab"
					role="link"
					tabindex="0"
					aria-posinset={index}
					aria-setsize={numberOfListedStudies}
					>
					<td class="speciesBar"></td>
					{#each cells as { cellContent, isMuted, tooltipContent, key }}
					<td
						class:isMuted={isMuted}
						class={`${key} ${key === 'species' ? `${cellContent}` : ''}`}
						use:tooltip={tooltipContent}>
						{ @html cellContent }
					</td>
					{/each}
				</tr>
				{/each}
			{:else if !numberOfListedStudies && numberOfStudies}
				<tr class="empty"><td class="warning" colspan="{ COLUMNS.length + 1 }">There are no results for your search. Please change the filters to generate a new search.</td></tr>
			{:else}
				<tr class="empty"><td class="warning" colspan="{ COLUMNS.length + 1 }">Loading the list of projects…</td></tr>
			{/if}
		</tbody>
	</table>
</div>

<style type="text/scss" scoped>
	@import '../styles/global.scss';

	$min-width: 700px;

	.list-info {
		// padding: calc(var(--spacing-padding) * 0.6) calc(var(--spacing-padding) * 0.8) calc(var(--spacing-padding) * 0.4);
		// width: calc(100vw - var(--spacing-padding) * 2);
		// margin: calc(var(--spacing-padding) * 0.4) var(--spacing-padding);
		margin: calc(var(--spacing-padding) * 0.4) var(--spacing-outer);
		// background-color: $color-monkey-0;
		// border: 1px solid $color-monkey-25;
		// border-radius: 3px;
		
		// span {
		// 	line-height: 1;
		// 	display: block;
		// 	text-align: center;
		// }

		@media screen and (min-width: $min-width) {
	    display: none !important;
	    visibility: hidden;
	  }
	}

	.list-list {
		max-width: 100vw;
		overflow-x: scroll;

		// https://stackoverflow.com/questions/16670931/hide-scroll-bar-but-while-still-being-able-to-scroll
		-ms-overflow-style: none;  /* Internet Explorer 10+ */
		scrollbar-width: none;  /* Firefox */

		&::-webkit-scrollbar { 
			display: none;  /* Safari and Chrome */
			width: 0;
			background: transparent;
		}

		table {
			position: relative;
  		border-collapse: collapse;
  		min-width: $min-width;
		}

		th, td {
			&.speciesBar {
				// max-width: 5px;
				padding: 0;
				min-width: 5px;
			}
		}

		th {
			transition: color $transition ease, border-bottom-color;
			position: sticky;
		  top: 0;
		  text-align: left;
		  cursor: pointer;
		  color: var(--color-gray-4);
		  background-color: var(--color-bg);

		  &:hover {
		  	color: var(--color-gray-5);

		  	svg {
		  		opacity: 1;
		  	}
		  }

		  // These values are calculated when running studies.js
		  // The remaining values should be used for genes and pi
		  &.species { width: 6%; }
		  &.experiment { width: 20%; }
		  &.ages { width: 7%; }
		  &.region { width: 21%; }
		  &.genes { width: 7%; }
		  &.sex { width: 2%; }
		  &.pi { width: 5%; }
		  &.name { width: 32%; }

		  div {
		  	display: grid;
		  	grid-template-columns: 1fr auto;
		  	grid-column-gap: 4px;
		  	white-space: nowrap;
		  	padding: 8px 2px;
		  	align-items: center;
		  }

		  svg {
		  	opacity: 0;
		  	transition: opacity $transition ease;
		  }

			.asc, .desc {
				transition: fill $transition ease;
				fill: var(--color-gray-2);
			}

			&::after {
		    content: '';
		    position: absolute;
		    bottom: 0;
		    left: 0;
		    right: 0;
		    border-bottom: 1px solid var(--color-gray-2);
		    transition: border-bottom-color $transition ease, border-bottom-width $transition ease;
			}

			&:first-child {
				&::after {
					border-bottom: 0;
				}
			}

			&.active {
				color: var(--color-gray-5);
				border-bottom-color: var(--color-accent);

				&::after {
			    border-bottom: 1px solid var(--color-accent);
				}

				svg {
					opacity: 1;
				}

				&.asc {
					.asc {
						fill: var(--color-gray-4);
					}
				}

				&:not(.asc) {
					.desc {
						fill: var(--color-gray-4);
					}
				}
			}
		}

		td {
			border-bottom: 1px solid var(--color-gray-border);
			padding: 4px 3px;

			@include query($wide) {
				padding: 5px 4px;
		  }

			@include query($extra-wide) {
				padding: 6px 4px;
		  }

			&.speciesBar {
				border-bottom: 1px solid var(--color-bg);
			}

			&.warning {
				padding: calc(var(--spacing-outer) * 6) 0;
				border: none;
				text-align: center;
			}
		}

		tbody {
			tr {
				margin-left: 4px;
				font-weight: $font-weight-default;

				&:not(.empty):hover, &:not(.empty):focus, &.isHover {
					background-color: var(--color-gray-1);
					cursor: pointer;
					outline: none;
				}

				&:last-child {
					td {
						border-bottom: 0;
					}
				}

				&.human {
					.speciesBar {
						background-color: $color-human-100;
					}

					.species {
						color: $color-human-hover;
					}
				}

				&.monkey {
					.speciesBar {
						background-color: $color-monkey-100;
					}

					.species {
						color: $color-monkey-hover;
					}
				}

				&.mouse {
					.speciesBar {
						background-color: $color-mouse-100;
					}

					.species {
						color: $color-mouse-hover;
					}
				}

				&.rat {
					.speciesBar {
						background-color: $color-rat-100;
					}

					.species {
						color: $color-rat-hover;
					}
				}

				&.killifish {
					.speciesBar {
						background-color: $color-killifish-100;
					}

					.species {
						color: $color-killifish-hover;
					}
				}

				&.worm {
					.speciesBar {
						background-color: $color-worm-100;
					}

					.species {
						color: $color-worm-hover;
					}
				}

				td.isMuted {
					color: var(--color-gray-4);
				}
			}
		}
		}
</style>