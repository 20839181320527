<script>
	import { ACTIVE_SPECIES, LIFESPANS } from '../stores.js';
	import { PADDING_AXIS_TOP, PADDING_GRAPH_LEFT, PADDING_GRAPH_TOP, PADDING_GRAPH_BOTTOM, PADDING_GRAPH_RIGHT, MIN_PADDING_BOTTOM, DOT_RADIUS, LIFESPAN_TICKS } from '../config.js';
	import {
		scaleTime,
		scaleTimeInvert,
		fromDays,
		getSpecieDateFromPercentage,
		between,
		formatSpeciesLabel
	} from '../scripts/utils.js';
	import Axis from '../Helper/Axis.svelte';
	import map from 'lodash/map';

	let width;
	let height;

	$: x1 = PADDING_GRAPH_LEFT;
	$: x2 = width - PADDING_GRAPH_RIGHT;
	$: y1 = PADDING_GRAPH_TOP;
	$: y2 = height - MIN_PADDING_BOTTOM
</script>

<div class="page-head" bind:clientWidth={width} bind:clientHeight={height}>
	<svg>
		{#if height}
		<text text-anchor="start" y={height - 17} class="title condensed">
			<tspan x={0}>Survival</tspan>
			<tspan dy="15" x={0}>percent</tspan>
		</text>
		{/if}
		<Axis height={height} width={width} isFirstAxis={true} />
	</svg>
</div>

<style type="text/scss" scoped>
	@import '../styles/global.scss';

	.page-head {
		z-index: -1;
		height: $height-head;

		svg {
			width: 100%;
			height: 100%;
		}
	}
</style>