<script>
	/* This Component displays the navigation for the result list */
	import { LIST_OFFSET, NUMBER_OF_PAGES, NUMBER_OF_LISTED_STUDIES } from '../stores.js';
	import { ITEMS_PER_PAGE } from '../config.js';
	import { onMount } from 'svelte';

	// All remaining studies
	let numberOfPages;
  NUMBER_OF_PAGES.subscribe(value => { numberOfPages = value; });

  // All remaining studies
	let numberOfListedStudies;
  NUMBER_OF_LISTED_STUDIES.subscribe(value => { numberOfListedStudies = value; });

  // The offset for navigating the list
  let offset;
  LIST_OFFSET.subscribe(value => { offset = value; });

  $: isPreviousDisabled = offset === 0
  $: isNextDisabled = offset >= (numberOfPages - 1) * ITEMS_PER_PAGE

  $: studyRangeMin = offset + 1
  $: studyRangeMax = Math.min(offset + ITEMS_PER_PAGE, numberOfListedStudies)

  // Navigation functions
  function nextPage () {
  	if (isNextDisabled) { return; }
    LIST_OFFSET.set(offset + ITEMS_PER_PAGE);
	}

	function previousPage () {
		if (isPreviousDisabled) { return; }
    LIST_OFFSET.set(Math.max(offset - ITEMS_PER_PAGE, 0));
	}

	function firstPage () {
    LIST_OFFSET.set(0);
	}

	function lastPage () {
    LIST_OFFSET.set((numberOfPages - 1) * ITEMS_PER_PAGE);
	}

	onMount(() => {
    document.addEventListener('keydown', ({ key, shiftKey }) => {
		  if (key === 'ArrowRight') {
		  	if (shiftKey) {
		  		lastPage();
		  	} else {
		  		nextPage();
		  	}
		  } else if (key === 'ArrowLeft') {
		  	if (shiftKey) {
		  		firstPage();
		  	} else {
		  		previousPage();
		  	}
		  }
		});
  });
</script>

<nav class="header-nav condensed" role="navigation" aria-label="Navigate the paginated project list">
	<!-- <button disabled={isPreviousDisabled} on:click={firstPage}>First</button> -->
	<button disabled={isPreviousDisabled} on:click={firstPage}>
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<polyline points="15 6 9 12 15 18"></polyline>
			<line x1="4" y1="4" x2="4" y2="20"></line>
		</svg>
	</button>
	<button disabled={isPreviousDisabled} on:click={previousPage} rel="prev">
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<polyline points="15 6 9 12 15 18"></polyline>
		</svg>
	</button>
	<span>{ studyRangeMin } – { studyRangeMax }</span>
	<button disabled={isNextDisabled} on:click={nextPage} rel="next">
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<polyline points="9 6 15 12 9 18"></polyline>
		</svg>
	</button>
	<button disabled={isNextDisabled} on:click={lastPage}>
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<polyline points="9 6 15 12 9 18"></polyline>
			<line x1="20" y1="4" x2="20" y2="20"></line>
		</svg>
	</button>
	<!-- <button disabled={isNextDisabled} on:click={lastPage}>Last</button> -->
</nav>

<style type="text/scss" scoped>
	@import '../styles/global.scss';

	.header-nav {
    grid-area: list-navigation;
		display: grid;
		grid-template-columns: repeat(5, auto);
		grid-column-gap: 0.5rem;
		justify-items: center;
		align-items: center;

		button:not(:disabled) {
			cursor: pointer;
		}

		button:last-child, button:nth-last-child(2) {
			justify-self: end;
		}

		button:first-child, button:nth-child(2) {
			justify-self: start;
		}

		svg {
			width: 16px;
			stroke-width: 1.5px;

			polyline, line {
				vector-effect: non-scaling-stroke;
			}
		}

		span {
			display: inline-block;
			min-width: 40px;
			text-align: center;
		}
	}
</style>