import tippy, { followCursor } from 'tippy.js';
import get from 'lodash/get'

export default function (node, props) {
  const instance = tippy(node, Object.assign({
  	followCursor: true,
  	plugins: [followCursor],
    theme: 'light-border',
    appendTo: () => document.getElementById('tooltip-container')
  }, props));

  return {
    update (payload) {
    	const content = get(payload, 'content')
    	if (content) {
    		instance.setContent(content);
    	}
    }
  };
}
