<script>
	/* This Component displays the resulting list of studies */
	import Header from './List-Header.svelte';
	import List from './List-List.svelte';
	import Facets from '../Facets/Facets.svelte';
</script>

<div class="page-list">
	<Header />
	<Facets />
	<List />
</div>

<style type="text/scss" scoped>
	@import '../styles/global.scss';

	.page-list {
		grid-area: list;
		display: grid;
		grid-template-rows: auto auto auto;
		grid-row-gap: var(--spacing-outer);
		height: 100%;
		width: 100%;
		margin-top: var(--spacing-outer);

    @include query($wide) {
			grid-template-rows: auto auto 1fr;
			margin-top: 0;
			width: calc(100% - var(--spacing-outer));
			grid-row-gap: calc(2 * var(--spacing-outer));
    }
	}
</style>