<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" id="monkey">
  <path class="color-2" d="M178.07 57.59l-3.21-.17V26.53a14.54 14.54 0 00-1.5-5 20.5 20.5 0 00-38.49-.16 19.69 19.69 0 00-17.45 34.72c-18.26 3.71-62.5 16.26-62.5 50.93v84h88.24S138 175.6 121.74 175.6l6-17.13 12-12.86L156.87 191h7.71V93.36h12l1.5-.1a17.84 17.84 0 100-35.67z"/>
  <path class="color-1" d="M164.9 93.36h-.35.35zM178 57.59l-3.21-.17V31l-.3-.49h-4.95A13.48 13.48 0 00156.1 44v40.1a7.77 7.77 0 00-.07 1 7.15 7.15 0 007.16 7.15h.54l1.17 1.17h11.64l1.5-.1a17.84 17.84 0 100-35.67z" />
  <path class="stroke" d="M126.19 111.35l13.7 34.27" />
  <path class="stroke no-fill" d="M153.18 191.02h15.35" />
  <circle class="fill" cx="166.22" cy="51.86" r="3.41" />
  <path class="stroke no-fill" d="M134.81 21.5a20.52 20.52 0 0138.55.07 14.54 14.54 0 011.5 5v30.85l3.21.17a17.84 17.84 0 110 35.67l-1.5.1h-12V191h-7.71l-17.14-45.41-12 12.86-6 17.13C138 175.6 143.16 191 143.16 191H50.63c-18 0-17.13-19.7-17.13-19.7V95.5a14.14 14.14 0 10-28.27 0v44.12" />
  <path class="stroke no-fill" d="M54.92 191v-84c0-34.22 43.09-46.89 61.78-50.78" />
  <path class="stroke color-2" d="M85.51 131.56a28.27 28.27 0 1131.21 47.15" />
  <path class="stroke no-fill" d="M129 59.94a19.7 19.7 0 1119.71-19.7" />
</svg>

<style type="text/scss">
	@import '../styles/global.scss';

	svg {
		.color-1, .color-2 {
			transition: fill $transition ease;
		}

		.color-1 {
			fill: $color-monkey-0;
		}

		.color-2 {
			fill: $color-monkey-50;
		}
	}

	:global(.specie-select) {
		&:hover, &:focus {
			#monkey {
				.color-1 {
					fill: $color-monkey-25;
				}

				.color-2 {
					fill: $color-monkey-hover;
				}
			}
		}
	}

	:global(.specie-select.isFilterActive input:not(:checked)) {
		& ~ #monkey {
			.color-1 {
				fill: var(--color-gray-1);
			}

			.color-2 {
				fill: var(--color-gray-2);
			}
		}
	}
</style>