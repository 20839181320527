<script>
  import { FILTER } from '../stores.js';
  import Option from './Option.svelte';
  import get from 'lodash/get'
  import tooltip from '../scripts/tooltip.js';
  import { removeFilter, addFilter } from '../scripts/utils.js';

  export let id;
  export let facet;
  let content;
  let isVisible = false;

  let isFiltered;
  FILTER.subscribe(value => {
    isFiltered = value.some(([ specie ]) => specie === id)
  });

  const resetFacet = () => {
    FILTER.update(oldFilter => removeFilter(oldFilter, id));
  };

  const selectAllFacet = () => {
    FILTER.update(oldFilter => addFilter(oldFilter, id, options.map(([label]) => label)));
  };

  $: label = get(facet, 'label');
  $: amount = get(facet, 'amount');
  $: options = get(facet, 'options');
  $: columnCount = Math.min(3, Math.floor(options.length / 5))

  $: facetTooltip = {
    followCursor: false,
    placement: 'bottom-start',
    maxWidth: '450px',
    interactive: true,
    content,
    allowHTML: true,
    arrow: false,
    // hideOnClick: false
    trigger: 'mouseenter focus click'
  }
</script>

<li class="facet" class:isFiltered={isFiltered}>
  <div use:tooltip={facetTooltip} class="preview" class:isVisible={isVisible}>
    <span class="condensed">{ label }</span>
    <svg
      on:click={resetFacet}
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-chevron-down"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      stroke-width="2"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round">
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <polyline points="12 10 18 5" class="right" transform-origin="12 7.5"></polyline>
      <polyline points="6 5 12 10" class="left" transform-origin="12 7.5"></polyline>
    </svg>
  </div>
  <ul class="facet-tooltip-content" bind:this={content} style={`grid-template-columns: repeat(${columnCount}, 1fr)`}>
    {#each options as option}
    <Option option={option} facet={id} />
    {/each}
    <footer style={`grid-column-end: ${columnCount + 1}`} class="facet-footer">
      <button on:click={selectAllFacet}>Deselect all</button>
      <button disabled={!isFiltered} on:click={resetFacet}>Select all</button>
    </footer>
  </ul>
</li>

<style type="text/scss" scoped>
  @import '../styles/global.scss';

  .facet {
    padding: 4px 6px;
    background-color: transparent;
    border-bottom: 1px solid var(--color-gray-2);
    border-radius: 0;
    transition: border-color $transition ease;
    color: var(--color-gray-4);

    &.isFiltered {
      border-bottom-color: var(--color-accent);
      color: var(--color-gray-5);

      svg:hover {
        color: var(--color-accent);
        cursor: pointer;
      }

      svg {
        .left {
          transform: translateX(3px) rotate(3deg);
        }

        .right {
          transform: translateX(-3px) rotate(-3deg);
        }
      }
    }

    .preview {
      display: flex;
      justify-content: space-between;
      transition: color $transition ease;

      &:hover, &:focus, &.isVisible {
        color: var(--color-gray-5);
      }

      svg {
        color: var(--color-gray-3);
        stroke-width: 1.5px;

        polyline {
          transition: transform $transition ease;
        }
      }
    }
  }

  :global(.facet-tooltip-content) {
    padding: 7px;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 5px;
    list-style: none;

    footer {
      grid-column-start: 1;

      button {
        transition: color $transition ease, border-color $transition ease;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: 3px;
        color: var(--color-gray-1);
        padding: 6px 9px;

        &:not(:disabled) {
          color: var(--color-gray-5);

          &:hover {
            color: var(--color-gray-5);
            border-color: var(--color-gray-1);
          }

          &:focus {
            color: var(--color-accent);
            border-color: var(--color-accent);
            outline: none;
          }
        }
      }
    }
  }

</style>