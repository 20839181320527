<script>
	import Facet from './Facet.svelte';
	import Option from './Option.svelte';
	import { FACETS, IS_FILTER_ACTIVE, FILTER, LIST_OFFSET } from '../stores.js';
	import map from 'lodash/map'

	let facets;
  FACETS.subscribe(value => {
  	facets = value;
  });

  let isFilterActive;
  IS_FILTER_ACTIVE.subscribe(value => {
  	isFilterActive = value;
  });

  const toggleActiveFilter = () => {
  	if (!isFilterActive) {
  		FILTER.set([]);
			LIST_OFFSET.set(0);
  	}
	};
</script>

<div class="page-facets hide-on-mobile">
	<div class="facets-options">
		<label class="pure-material-checkbox">
			<input
				class="facet-checkbox"
				type="checkbox"
				bind:checked={$IS_FILTER_ACTIVE}
				on:change={toggleActiveFilter} />
			<div><span class="facet-option">Apply filters</span></div>
		</label>
	</div>
	<ul class="facets" class:isActive={$IS_FILTER_ACTIVE}>
		{#each facets as { id, facet }}
		<Facet {id} {facet} />
		{/each}
	</ul>
</div>

<style type="text/scss" scoped>
	@import '../styles/global.scss';

	.page-facets {
		padding: var(--spacing-padding) var(--spacing-outer);

		@include query($wide) {
			padding: 0;
    }

		.facets-options {
			margin-bottom: 0.5rem;
		}

		.facets {
			list-style: none;
			display: grid;
			grid-auto-flow: column;
			grid-column-gap: 10px;
			transition: opacity $transition ease;
			opacity: 0.3;
			pointer-events: none;

			&.isActive {
				opacity: 1;
				pointer-events: all;
			}
		}
	}

</style>