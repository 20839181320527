<script>
  import { ACTIVE_SPECIES, LIFESPANS } from '../stores.js';
  import { PADDING_AXIS_TOP, PADDING_GRAPH_LEFT, PADDING_LIFESPAN_TOP, PADDING_GRAPH_BOTTOM, PADDING_GRAPH_RIGHT, MIN_PADDING_BOTTOM, DOT_RADIUS, LIFESPAN_TICKS } from '../config.js';
  import {
    scaleTime,
    scaleTimeInvert,
    fromDays,
    getSpecieDateFromPercentage,
    between,
    formatSpeciesLabel
  } from '../scripts/utils.js';
  import Axis from '../Helper/Axis.svelte';
  import { scaleLinear } from 'd3-scale';
  import { line as lineFunc, curveStepAfter } from 'd3-shape';
  import tooltip from '../scripts/tooltip.js';
  import map from 'lodash/map';
  import reverse from 'lodash/reverse';
  import isNull from 'lodash/isNull';
  import { format } from 'd3-format';
  import { onMount } from 'svelte';

  const f = format('.0%');

  // We need these things for the hover effect
  let m = null; // Cursor position
  let positionPercentage = 0;
  let canvas; // Is binded to the div
  let hoverArea; // Is binded to the div
  let pt; // The moving dot
  let inverse;

  let width;
  let height;

  let lifespans;
  LIFESPANS.subscribe(value => { lifespans = value; });

  let activeSpecies;
  ACTIVE_SPECIES.subscribe(value => { activeSpecies = value; });

  $: x1 = PADDING_GRAPH_LEFT;
  $: x2 = width - PADDING_GRAPH_RIGHT;
  $: y1 = PADDING_LIFESPAN_TOP;
  $: y2 = height - MIN_PADDING_BOTTOM

  $: y = scaleLinear()
    .domain([1, 0]) // Percentage of alive
    .range([y1, y2])

  $: x = scaleLinear()
    .domain([0, 1]) // Percentage of life
    .range([x1, x2])

  $: lines = reverse(map(lifespans, ({ data, specie }) => {
    const points = data.map(([time, alive]) => [x(time), y(alive), alive])
    const d = lineFunc(d => d[0], d => d[1]).curve(curveStepAfter)(points)
    const isActive = activeSpecies.includes(specie);

    return {
      d,
      points,
      specie,
      isActive
    }
  }))

  $: dots = map(lines, ({ points, specie }) => {
    let cy = y1;
    let alive = 1;
    for (let i = 0; i < points.length; i++) {
      const [cx, _cy, _alive] = points[i];
      if (cx <= m) {
        cy = _cy
        alive = _alive
      } else {
        break;
      }
    }

    return {
      specie,
      cy,
      alive
    }
  }).filter(({ specie }) => activeSpecies.includes(specie))

  $: ticks = y.ticks(LIFESPAN_TICKS).map((tick) => {
    const [x1, x2] = x.range();
    const _y = y(tick) || 0
    return {
      y: _y,
      x1,
      x2: x2 || 0,
      tick: f(tick)
    }
  })

  function getTooltipContent (dots) {
    const max = Math.min.apply(Math, dots.map((o) => o.cy))

    const species = reverse(dots.map(({ specie, alive }) => `<tr><td class="color-${specie}">${formatSpeciesLabel(specie)}</td><td>${getSpecieDateFromPercentage(specie, positionPercentage)}</td><td>${f(alive)}</td>`)).join('')

    return {
      tooltip: {
        followCursor: false,
        showOnCreate: true,
        hideOnClick: false,
        triggerTarget: hoverArea,
        content:
          `<table class="lifespans">
            <thead>
              <tr>
                <th>Species</th>
                <th>Age</th>
                <th>Survival</th>
              </tr>
              <tbody>
              ${species}
            </tbody>
          </table>`,
        allowHTML: true
      },
      cy: max
    }
  }

  $: legend = getTooltipContent(dots)

  function handleMousemove ({ clientX }) {
    pt.x = clientX;
    m = between(pt.matrixTransform(inverse).x, x1, x2);
    positionPercentage = x.invert(m);
  }

  onMount(() => {
    pt = canvas.createSVGPoint();
    inverse = canvas.getScreenCTM().inverse()
  });
</script>

<div class="page-lifespan" bind:clientWidth={width} bind:clientHeight={height}>
  <svg bind:this={canvas}>
    <g class="ticks">
      {#each ticks as { y, x1, x2, tick }}
      <line x1={0} x2={x2} y1={y} y2={y} class="tick" />
      <text x={0} text-anchor="start" y={y} class="tick bg" dominant-baseline="middle">{ tick }</text>
      <text x={0} text-anchor="start" y={y} class="tick" dominant-baseline="middle">{ tick }</text>
      {/each}
    </g>
    <Axis height={height} width={width} isFirstAxis={false} />
    {#each lines as { d }, i}
    <g class="lifespan-lines">
      <path d={d} class="lifespan-line inactive" />
    </g>
    {/each}
    {#each lines as { d, isActive, specie }, i}
    <g class="lifespan-lines actives">
      <path d={d} class="{`lifespan-line ${specie}`}" class:isActive={isActive} />
    </g>
    {/each}
    {#if !isNull(m)}
    <g>
      <g>
        <circle
          class="tooltip-anchor"
          cx={m}
          cy={legend.cy}
          r="5"
          use:tooltip={legend.tooltip} />
      </g>
      <g class="lifespan-indicators">
        {#each dots as { cy, specie }}
        <circle
          data-species={specie}
          cx={m}
          cy={cy}
          r={DOT_RADIUS}
          class="{`lifespan-indicator ${specie}`}" />
        {/each}
      </g>
    </g>
    {/if}
    <path
      on:mousemove={handleMousemove}
      bind:this={hoverArea}
      d={`M${0} ${y1} H ${x2 + 10} V ${y2} H ${0} V ${y1}`}
      class="hover-area"
      class:isEmpty={!activeSpecies.length} />
  </svg>
</div>

<style type="text/scss" scoped>
  @import '../styles/global.scss';

  .page-lifespan {
    grid-area: lifespan;
    z-index: 2;
    height: $height-lifespan;

    svg {
      width: 100%;
      height: 100%;

      circle {
        &.lifespan-indicator {
          fill: var(--color-bg);
          stroke-width: 2px;
        }
      }

      .tooltip-anchor {
        opacity: 0;
      }

      .lifespan-indicators {
        opacity: 0;
        transition: opacity $transition ease 0.25s; // 250ms is the same duration as tippy

        circle {
          &.human { stroke: $color-human-100; }
          &.monkey { stroke: $color-monkey-100; }
          &.mouse { stroke: $color-mouse-100; }
          &.rat { stroke: $color-rat-100; }
          &.killifish { stroke: $color-killifish-100; }
          &.worm { stroke: $color-worm-100; }
        }
      }

      &:hover {
        .lifespan-indicators {
          opacity: 1;
          transition: opacity 0s ease 0s;
        }
      }

      .hover-area {
        fill: transparent;

        &.isEmpty {
          pointer-events: none;
        }
      }

      text.tick {
        font-size: var(--font-size-9);
        fill: var(--color-gray-4);

        &.bg {
          stroke: var(--color-bg);
          stroke-width: 6px;
        }
      }

      line.tick {
        stroke: var(--color-gray-border);
        stroke-dasharray: 2
      }

      path {
        &.lifespan-line {
          fill: none;
          stroke-width: 1.5px;
          vector-effect: non-scaling-stroke;
          transition: opacity $transition ease;

          &:not(.isActive) {
            opacity: 0;
          }

          &.inactive {
            opacity: 1;
            stroke: var(--color-gray-2);
          }

          &.human { stroke: $color-human-100; }
          &.monkey { stroke: $color-monkey-100; }
          &.mouse { stroke: $color-mouse-100; }
          &.rat { stroke: $color-rat-100; }
          &.killifish { stroke: $color-killifish-100; }
          &.worm { stroke: $color-worm-100; }
        }
      }
    }
  }
</style>